.question-changes-dialog {
    .mat-dialog-container {
      overflow: hidden;
      padding: 0;
      padding-bottom: 24px;
      min-width: 28rem;
  
      .mat-dialog-content {
        height: calc(100% - 104px);
        padding: 2rem;
        margin: 0;
        font-size: calc(12px * var(--font-factor));
        color: var(--text-color);
        font-weight: 300;
      }
  
      .mat-dialog-actions {
        justify-content: space-evenly;
        box-shadow: 0px 0px 3px #00000029;
        padding: 4px 0 !important;
      }
    }
  }

.svr-sections-list-question-compare-wrapper .mat-dialog-container{
  background-color: var(--almost-blank-color)
}
