vista-entity-list {
  .tools {
    display: flex;
    align-items: center;
    min-height: 24px;
    .others {
      display: flex;
      align-items: center;
      mat-slide-toggle {
        margin-right: auto;
      }
      vista-filter-menu {
        margin-left: auto;

        + vista-filter-menu {
          margin-left: inherit;
        }
      }
    }
  }
}
