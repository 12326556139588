$regular-text-size: calc(0.75rem * var(--font-factor)) !important;
$small-text-size: calc(0.625rem * var(--font-factor)) !important;

.p-column-filter-operator {
  background: var(--almost-blank-color) !important;
}

.p-inputtext {
  font-size: $regular-text-size;
  font-family: 'Open Sans', sans-serif;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--input-underline-color);
  padding: 0.25rem 0;

  &:hover {
    border-color: var(--primary-color) !important;
    border-color: var(--input-underline-color) !important;
  }

  &:enabled:focus {
    box-shadow: none !important;
    border-color: var(--primary-color) !important;
  }
}

.p-datepicker {
  font-size: $regular-text-size;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding: 0;

  .p-datepicker-header {
    font-weight: 500;
    border: 0;
    padding: 0.5rem 0.5rem 0 0.5rem;

    .p-datepicker-next:focus {
      box-shadow: none;
    }
  }

  table {
    font-size: $regular-text-size;
    font-family: 'Open Sans', sans-serif;
    margin: 0;

    thead {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    th {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.38);
    }

    td {
      padding: 0.25rem;

      >span {
        width: 32px;
        height: 32px;
        line-height: 32px;

        &.p-highlight {
          background: rgba(18, 56, 133, 0.65) !important;
          color: var(--almost-blank-color);
        }
      }
    }
  }
}

.p-dropdown {
  font-size: $regular-text-size;
  font-family: 'Open Sans', sans-serif;
  border: 0 !important;
  border-bottom: 1px solid var(--input-underline-color) !important;
  border-radius: 0;
  background: inherit;
  box-shadow: none !important;

  &:not(.p-disabled).p-focus {
    border-color: var(--primary-color) !important;
  }

  .p-dropdown-items-wrapper {
    font-size: $regular-text-size;
    font-family: 'Open Sans', sans-serif;
  }

  .pi-chevron-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;

    &:before {
      content: '';
    }
  }

  .p-dropdown-trigger {
    width: 18px;
  }
}

.p-button {
  font-size: $regular-text-size;
  font-family: 'Open Sans', sans-serif;
  border: 0 !important;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
  }

  &:not(.p-button-danger).p-button-text {
    color: var(--primary-color) !important;
  }

  .p-button-icon {
    &.pi-trash {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23e9406c' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-trash'%3E%3Cpolyline points='3 6 5 6 21 6'%3E%3C/polyline%3E%3Cpath d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'%3E%3C/path%3E%3C/svg%3E");
      width: 16px;
      height: 16px;

      &:before {
        content: '';
      }
    }
  }
}

.p-column-filter-overlay-menu {
  border-radius: 4px;

  .p-column-filter-constraint {
    padding: 0.75rem 1rem 0.5rem 1rem !important;
  }

  .p-column-filter-buttonbar {
    button {
      border: 0;
      background: #fff;
      color: var(--primary-color);

      &:hover,
      &:active {
        border: 0 !important;
        color: var(--primary-color) !important;
        background-color: rgba(18, 56, 133, 0.06) !important;
        box-shadow: none !important;
      }

      &:first-child {
        color: var(--secondary-color);

        &:hover,
        &:active {
          border: 0 !important;
          color: var(--secondary-color) !important;
          background-color: rgba(233, 64, 109, 0.06) !important;
          box-shadow: none !important;
        }
      }
    }
  }

  &.text-small {

    .p-inputtext,
    .p-datepicker,
    .p-datepicker .table,
    .p-dropdown,
    .p-dropdown .p-dropdown-items-wrapper,
    .p-button {
      font-size: $small-text-size
    }
  }
}

button {
  &:focus {
    box-shadow: none;
  }

  .pi {
    font-size: 0.8rem;
  }
}

.table-actions-menu {
  font-weight: 300;
  width: auto !important;

  button {
    width: 100%;
    height: 35px;
    display: block;
    text-align: left;
    line-height: 35px;
    color: #666;

    &.selected {
      background-color: #ddf6fd;
    }

    &:hover {
      background-color: #ddf6fd !important;
    }
  }
}