@use '@angular/material' as mat;
@import './theme.scss';

$primary: map-get($vista-theme, primary);
$accent: map-get($vista-theme, accent);

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  z-index: 1;
  font-weight: 300;
  color: mat.get-color-from-palette($primary, lighter-contrast);
}

:root {
  /* Colors: */
  --text-color: #666666;
  --icons-color: #666666;
  --primary-color: #123985;
  --secondary-color: #e9406c;
  --error-color: #f44336;
  --panel-header: #ddf6fd;
  --info-color: #ddf6fd;
  --disable-color: #cccccc;
  --page-background-color: #eeeeee;
  --almost-blank-color: #f8f8f8;
  --muted-color: #e5e5e5;
  --shadow-color: #00000029;
  --info-light-color: #F0FBFD;
  --success-color: #3E9452;
  --success-light-color: #D4EDDA;
  --dark-red-color: #9E1C27;
  --light-red-color: #F8D7DA;
  --orange-color: #F28123;
  --yellow-color: #FFCA3A;
  --inactive: #CCCCCC;
  --active-color: #aaa;
  --foreground-color: #fafafa;
  --input-underline-color: rgba(0, 0, 0, 0.42);
  --font-factor: 1.1;
  --approved-status-color: #e6f4e9;
  --rejected-status-color: #fae7e9;
  --inprogress-status-color: #fff8e5;
  --submitted-status-color: #e7f7fc;
}

@import './modules/layout.scss';
@import './modules/table.scss';
@import './modules/entity-list.scss';
@import './modules/entity-checklist.scss';
@import './modules/entity-panel.scss';
@import './modules/form-elements.scss';
@import './modules/tree.scss';
@import './modules/main-changes.scss';
@import './modules/monitor.scss';
@import './modules/site-visits.scss';
@import './modules/questions.scss';
@import './modules/utils.scss';
@import './modules/scrollbars.scss';
@import './modules/expander-list.scss';
@import './modules/tasks.scss';
@import './modules//incidents.scss';
@import './modules/vista-dialog.scss';
