.main-changes-dialog {
  .mat-dialog-container {
    overflow: hidden;
    padding: 0 0 8px 0;

    i-feather {
      stroke-width: 1.5px !important;
      width: 15px !important;
      height: 16px !important;
    }

    .close {
      color: var(--text-color);
    }
  }
}
