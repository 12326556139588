.action-point-status-dialog {
  .icon-button {
    width: 18px;
    height: 18px;
    stroke-width: 1.5px;
    line-height: 18px;
  }
}


.wrap-tooltip {
  white-space: pre;
}

.monitor-header-select-search {
    .mat-form-field {
      margin-right: 1rem;
  
      .mat-form-field-underline {
        display: none;
      }
  
      .mat-form-field-infix {
        overflow: hidden;
      }
  
      &.mat-form-field-disabled .mat-select-trigger {
        cursor: not-allowed !important;
      }
    }

    &.my-site-visits {
      .mat-form-field {
        max-width: 7rem;
      }
    }
}

.svr-summary-icon-button {
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    padding: 0;
    width: 26px;
    height: 26px;
    flex: 0 0 auto;
    outline: none;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .icon {
      width: 16px !important;
      height: 15px !important;
      color: #fff !important;
      stroke-width: 1.5px !important;
    }
  }

.svr-lock-site-visit-icon {
  cursor: default;
  width: 18px !important; 
  height: 18px !important; 
  line-height: 18px !important; 
}


.svr-submit-dialog {
  .mat-dialog-container {
    overflow: hidden;
    padding: 0;
    width: 27rem;
  }
}