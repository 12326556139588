.duplicate-dialog {
  .mat-dialog-container {
    overflow: hidden;
    padding: 0;
    padding-bottom: 24px;
    width: 28rem;

    .mat-dialog-content {
      height: calc(100% - 104px);
      padding: 2rem;
      margin: 0;
      font-size: calc(12px * var(--font-factor));
      color: var(--text-color);
      font-weight: 300;
    }

    .mat-dialog-actions {
      justify-content: center;
      box-shadow: 0px 0px 3px #00000029;
      padding: 4px 0 !important;
      min-height: 50px;
    }
  }
}



.mat-autocomplete-panel {
  overflow: hidden;

  .mat-option {
    font-size: calc(10px * var(--font-factor));
    font-weight: 300;
    height: 26px;
    
  }

  .scrollable-container {
    max-height: 12rem;
    overflow-y: auto;
  }
}

.locked-site-visit-template {
  text-overflow: unset !important;
  line-height: 10px !important;

  i-feather {
    height: 18px;
    width: 18px;
  }
}