.mat-dialog-container {
    padding: 0 !important;
    overflow: hidden !important;
}

// Header
.vista-dialog-header {
    padding: 0px 20px;
    height: 50px;
    display: flex; 
    align-items: center; 
    background-color: var(--foreground-color);
    box-shadow: 0px 0px 2px #00000029;

    .vista-dialog-title {
        font: normal normal normal 16px/17px 'Open Sans';
        color: var(--text-color);
        text-align: left;
        margin: 0;
        flex: 1;
    }

    .mat-icon-button {

        i-feather {
            width: 20px;
            height: 20px;
            stroke-width: 1px;
            line-height: 0 !important;
        }
    }
}

// Content
.mat-dialog-content {
    margin: 30px 15px;
}

.vista-dialog-content {
    .vista-dialog-content-description {
        font: normal normal 300 12px/17px 'Open Sans';
        color: var(--text-color);
        margin: 0;
        white-space: pre-wrap;
        margin-bottom: 12px;
    }
}

// Footer
.mat-dialog-actions {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 50px !important;
}

.vista-dialog-actions {
    min-height: 50px;
    height: 50px;
    justify-content: center;
    align-items: center ;
    background-color: var(--foreground-color);
    box-shadow: 0px 0px 3px #00000029;

    button {
        margin: 5px;
        font: normal normal 550 10px/21px 'Open Sans';
        padding: 4px 14px;
        border: 0;
        border-radius: 20px;
        color: white;
    }
 
    .back-button {
        background-color: transparent;
        padding: 2px 12px;
        border: 2px solid;
        color: var(--primary-color);
        box-sizing: border-box;
    }
}