.clickable {
  cursor: pointer;
}
.not-clickable {
  cursor: default;
}
.action {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.text-centered {
  text-align: center;
}

.animated-ellipsis {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;

  &::after {
    display: inline-block;
    content: '...';
    animation: animated-ellipsis-keyframes 1s steps(4, jump-none) infinite;
  }

  @keyframes animated-ellipsis-keyframes {
    0% {
      transform: translateX(-100%);
    }
  }
}
