vista-entity-checklist {
  .mat-checkbox {
    .mat-checkbox-layout {
      display: flex;
      flex: 1;
      .mat-checkbox-label {
        flex: 1;
      }
    }
  }
}
