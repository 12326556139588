.expander-header {
  border-radius: 0;
  padding: 0;
  box-shadow: none !important;
  background-color: transparent;
  overflow: visible;
  cursor: default;

  &.empty ::ng-deep .mat-expansion-panel-content {
    display: none;
  }

  .expander-row-header {
    font-size: calc(.625rem * var(--font-factor));

    &:hover {
      cursor: default;
      background-color: transparent !important;
      z-index: 0
    }
  }
}

.expander-row .expander-row-header.expander-row-edit {
  align-items: flex-end;
  height: 4rem;

  .mat-form-field {
    width: 100%;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.expander-row {
  border-radius: 0 !important;
  padding: 0;
  box-shadow: 0 0 .125rem var(--shadow-color) !important;
  overflow: visible;

  .icon {
    color: var(--icons-color);
    width: 1.125rem;
    height: 1.125rem;

    &.icon-small {
      width: .75rem;
      height: .75rem;
    }

    &.icon-primary {
      color: var(--primary-color);
    }

    &.icon-danger {
      color: var(--secondary-color);
      stroke-width: 1px;
      cursor: pointer;
    }

    &.icon-move {
      cursor: move;
    }
  }

  &.empty  .mat-expansion-panel-content {
    display: none;
  }

  .expander-row-header {
    padding: 0 1rem;
    height: 3rem;
    align-items: center;
    flex-grow: 0;
    font-size: calc(.75rem * var(--font-factor));
    color: var(--text-color);

    &:hover:not(.expander-row-edit) {
      background-color: var(--almost-blank-color) !important;
      z-index: 1;
    }

    &.mat-expanded {
      height: 3rem;
    }

     > .mat-content {
      display: grid;
      grid-column-gap: 1rem;
      align-items: center;
      justify-items: flex-start;
    }
  }

  .fields-row-body {
    color: var(--text-color);
  }

  .mat-expansion-panel-body {
    padding: 1rem !important;
    background-color: var(--almost-blank-color);
    border-top: 1px solid var(--muted-color);
  }
}


