input {
  outline: none;
}

mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: 12px;
    width: 32px;
    background-color: var(--muted-color);
  }

  .mat-slide-toggle-thumb {
    width: 18px;
    height: 18px;
  }

  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14px" height="14px" ><path fill="%23ffffff" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.mat-disabled {
    .mat-slide-toggle-thumb {
      background-color: var(--disable-color);
    }
  }
}

.mat-slide-toggle {
  vertical-align: center;
  display: inline-block;

  .mat-slide-toggle-bar {
    transform: scale(0.8);
  }

  &.mat-checked {
    .mat-slide-toggle-content {
      color: var(--primary-color);
      font-weight: normal;
    }
  }
}

.mat-slide-toggle:not(.mat-checked) {
  font-weight: light;
  letter-spacing: 0.18pt;
}

.mat-slide-toggle-content {
  &.false-label-active {
    color: var(--primary-color) !important;
    font-weight: normal;
  }

  &:not(.false-label-active) {
    font-weight: light;
    letter-spacing: 0.18pt;
  }
}

.mat-checkbox {
  &.mat-checkbox-disabled {
    opacity: 0.38;
    
    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: #123985;
      }
    }

    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: #e9406c;
      }
    }
  }

  &.mat-checkbox-checked {
    font-weight: normal;
    letter-spacing: normal;

    .mat-checkbox-label {
      color: var(--primary-color) !important;
      font-weight: normal;
    }

    .mat-checkbox-checkmark {
      width: 12px;
      height: 12px;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
    }
  }
}

.mat-checkbox:not(.mat-checkbox-checked, .mat-checkbox-disabled) {
  font-weight: light;
  letter-spacing: 0.18pt;
}

.select-search-panel {
  .mat-select-search-clear {
    width: 24px;
    height: 24px;
    line-height: 10px;
  }
}

.font-x-small, .font-x-small .mat-form-field {
  font-size: calc(.75rem * var(--font-factor));
}

.mat-form-field-infix {
  width: unset;
}