vista-entity-panel {
  .mat-form-field {
    color: #666666;
    font-weight: 100;
    font-size: small;
    display: block;

    &.font-x-small {
      font-size: calc(.75rem * var(--font-factor));
    }

    .mat-form-field-label {
      color: #000;
    }

    .mat-form-field-ripple {
      height: 1px;
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background: #e0e0e0;
      }
    }

    .mat-form-field-underline {
      background: #e0e0e0;
    }
  }

  .mat-tab-group {
    position: absolute;
    bottom: 56px;
    top: 69px;
    left: 0;
    right: 0;

    &.no-scroll {
      .mat-tab-body-content {
        overflow: hidden;
      }
    }

    .mat-tab-header {
      overflow: visible;
      .mat-tab-label-container {
        overflow: visible;
        .mat-tab-list {
          overflow: visible;

          .mat-tab-label {
            min-width: 2rem !important;
            padding: 0px auto 0px auto !important;
            margin: 0px !important;
            height: 30px !important;
            font-weight: lighter;
            font-size: calc(10px * var(--font-factor));
            width: 110px;

            &.mat-tab-label-active {
              color: #123985;
              background-color: var(--info-color);
              font-weight: 400;
            }

            .error {
              color: var(--error-color);
            }

          }

          .mat-ink-bar {
            height: 1px;
            bottom: -1px;
          }
        }
      }
    }

    .mat-tab-body {
      .mat-tab-body-content {
        padding: 1rem;
        box-sizing: border-box;
        .mat-form-field {
          margin: 0;
        }
      }
    }

    .mat-tab-body-wrapper {
      height: 100%;
    }
  }

  .ngx-datatable.material {
    box-shadow: none;
  }
}
