@use '@angular/material' as mat;
@import '../theme.scss';

// Remove in when a new version of the Map component is available

.buttons-levels {
  .button-wrap:not(.active) {
    .icon-pitch {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='24.686' viewBox='0 0 32 24.686'%3E%3Cpath d='M32.257,23.743H25.4v-12.8h6.857V8.2a.457.457,0,0,0-.457-.457H19.914v5.12a4.571,4.571,0,0,1,0,8.96v5.12H31.8a.457.457,0,0,0,.457-.457Zm0-1.829V12.771H27.229v9.143ZM5.743,23.743v2.743a.457.457,0,0,0,.457.457H18.086v-5.12a4.571,4.571,0,0,1,0-8.96V7.743H6.2a.457.457,0,0,0-.457.457v2.743H12.6v12.8Zm0-1.829h5.029V12.771H5.743Zm12.343-7.159a2.743,2.743,0,0,0,0,5.175Zm1.829,0V19.93a2.743,2.743,0,0,0,0-5.175ZM35,8.2V26.486a3.2,3.2,0,0,1-3.2,3.2H6.2a3.2,3.2,0,0,1-3.2-3.2V8.2A3.2,3.2,0,0,1,6.2,5H31.8A3.2,3.2,0,0,1,35,8.2Z' transform='translate(-3 -5)' fill='none' stroke='%23000000' fill-rule='evenodd'/%3E%3C/svg%3E%0A") !important;
    }
  }
}

.app-content {
  background-color: var(--page-background-color);
  font-size: calc(0.75rem * var(--font-factor));
  padding: 0 56px 56px 56px;
  color: var(--text-color);
  flex-direction: column;
  overflow: hidden;
  display: flex;
  height: 100%;

  .app-content-header {
    display: flex;
    align-items: center;
    height: 3.5rem;
    gap: 1rem;
  }

  .app-content-pagination-info {
    margin-top: 8px;
  }
}

button.create-entity {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

.mat-drawer-backdrop {
  &.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding-right: 10px;

  &:last-child {
    padding-right: 0;
  }

  &.no-padding {
    padding: 0;
  }
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
  max-width: 30%;
}

.width-33 {
  width: 33.333%;
  max-width: 33.333%;
}

.width-40 {
  width: 40%;
  max-width: 40%;
}

.width-50 {
  width: 50%;
  max-width: 50%;
}

.width-70 {
  width: 70%;
  max-width: 70%;
}

.full-width {
  width: 100%;
}

.inline {
  box-sizing: border-box;
  flex-direction: column;
  padding: 0 10px;
}

.filters {
  display: flex;
  font-size: calc(12px * var(--font-factor));
  padding: 16px 0;
  align-items: center;
  min-height: 24px;
  color: #666666;

  a {
    display: inline-flex;
    color: #666;
    text-decoration: none;
    margin-right: 1rem;
  }

  .left {
    display: flex;
    text-align: left;
    flex: 1;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}

.x-small {
  font-size: calc(0.5rem * var(--font-factor));
}

.svr-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 2px #00000029;
  height: 100%;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .svr-card-actions {
    box-shadow: 0px 0px 3px #00000029;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    z-index: 1;
    height: 4rem;
    align-items: center;
    font-size: 12px;
    gap: 1rem;

    button {
      height: 2.5rem;
      width: 6rem;

      .mat-button-wrapper {
        line-height: 12px;
      }

      i-feather {
        display: block;
        width: 18px;
        margin: auto;
        stroke-width: 1px;
      }
    }
  }
}

.svr-card-header {
  background-color: #fafafa;
  box-shadow: 0px 0px 2px #00000029;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  opacity: 1;
  display: flex;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    stroke-width: 1.5px !important;
  }
}

.svr-card-body {
  padding: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  font-size: 14px;
  height: calc(100% - 3rem);
  overflow-y: auto;

  .svr-card-row {
    display: flex;
    gap: 1rem;
  }

  .icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    stroke-width: 1.5px !important;
  }
}

.svr-action-point-comment-wrapper {

  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

mat-tooltip-component {
  .mat-tooltip {
    white-space: pre-line;

    &:hover {
      display: none;
    }
  }
}

//--------------------------------------------
// DIALOG SIDE PANEL
//--------------------------------------------
@keyframes slide {
  100% {
    right: 0;
  }
}

$background-palette: map-get($vista-theme, background);

.card-popup {
  position: fixed !important;
  right: -100vw;
  animation: slide 0.1s forwards;
  animation-delay: 0.1s;

  .mat-dialog-container {
    border-radius: 0;
    background-color: mat.get-color-from-palette($background-palette, background);
  }
}

.apps-card {
  .mat-dialog-container {
    padding: 0;
  }
}

.mat-select-panel {
  background-color: mat.get-color-from-palette($background-palette, background);
}

.toast-top-right {
  top: 72px;
  right: 16px;
}

.toast-container .ngx-toastr {
  color: #666666;
  font-size: calc(10px * var(--font-factor));
  padding: 15px;
  width: auto;
  min-width: 200px;
  white-space: pre-line;
  max-width: 384px;
}

.toast-success {
  background-color: #d4edda;
  background-image: none;
}

.toast-warning {
  background-color: #fae4ad;
  background-image: none;
}

.toast-error {
  background-color: #f8d7da;
  background-image: none;
}

.card-popup {
  .mat-dialog-container {
    border-radius: 0;
  }
}

.apps-card {
  .mat-dialog-container {
    padding: 0;
  }
}

i-feather.action {
  &:last-child {
    margin-right: 0;
  }

  color: #123985;
  width: 18px;
  display: inline-block;
  margin-right: 10px;

  &.secondary {
    color: #e9406c;
    transform: translate(0, 0);
  }

  &.primary {
    color: #123985;
  }

  &.disabled {
    color: #cccccc;
  }
}

.user-popup-card {
  .mat-dialog-container {
    border-radius: 0;
    padding: 16px;
    overflow: hidden;
  }
}

.flex-grow {
  flex-grow: 1;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiline-tooltip {
  white-space: pre-line;
}

angular-editor {
  .angular-editor-toolbar {
    border: 0 !important;
    padding: 8px 8px 0px 8px !important;
    background-color: var(--almost-blank-color) !important;

    button {
      color: var(--text-color);

      .foreground ::after {
        background: var(--text-color) !important;
      }

      &.active {
        background: var(--info-color) !important;
      }
    }
  }

  .angular-editor-textarea {
    border: 0 !important;
  }
}

@media(max-width: 700px) {
  .app-content {
    padding: 0 20px 20px 20px;
  }
}