// Css styling using the CSS Styling currently in draft
* {
  scrollbar-width: thin;
  scrollbar-color: #999;
}

// Css styling using webkit prefixes (does not work on IE or Firefox)
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

* {
  // Show scrollbars only on mouse over
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(153, 153, 153, 0.8);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(85, 85, 85, 0.8);
    }
  }
}
